<template>
  <div
    class="withdrawal container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <!-- <h3>昵称:{{ userInfo.info.usernick }}</h3> -->
    <div
      class="card shadow default border-r5 con mt-10"
      @click="rechargeType('local')"
    >
      <van-icon name="paid" style="margin-left: 6px" />
      <span class="fz14"
        >{{ $t("public.text27") }} :
        <strong class="fz16 green">{{ userInfo.info.balance }}</strong></span
      >
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <!-- <p class="fz14 txt-right wt90 mg-center con">
          剩余打码量 <strong class="red"> 0.00</strong>
        </p> -->
        <van-field
          v-model="form.money"
          type="number"
          :label="$t('public.text28')"
          :label-width="locale === 'en' ? '130px' : '60px'"
        />
        <van-field
          v-model="form.password"
          type="password"
          :label="$t('public.text29')"
          :label-width="locale === 'en' ? '130px' : '60px'"
        />
        <van-field
          v-model="bankName"
          readonly
          is-link
          class="uploader"
          :label="$t('public.text30')"
        />
        <van-field
          v-model="form.captcha"
          :label="$t('login.verification')"
          :label-width="locale === 'en' ? '130px' : '60px'"
          class="mt-10"
        >
          <template #button>
            <img
              :src="imgCaptcha"
              alt=""
              class="captcha"
              @click="imgCaptcha = getCaptcha()"
            />
          </template>
        </van-field>
        <!-- <p class="fz13 txt-left wt90 mg-center con" style="line-height: 25px; height: 30px">
          <span style="float: left">手续费：0.00</span>
          <span style="float: right">到账金额:22.00</span>
        </p> -->
      </van-cell-group>
      <div style="margin-top: 16px">
        <van-button round block type="primary" native-type="submit">
          {{ $t("public.submit") }}
        </van-button>
      </div>
    </van-form>
    <!-- <van-popup v-model:show="showPicker" position="bottom">
      <van-picker :columns="columns" @confirm="onConfirm" @cancel="showPicker = false" />
    </van-popup> -->
  </div>
</template>

<script setup>
import { ref, reactive, toRaw } from "vue";
import { Toast, Dialog } from "vant";
import { getCaptcha } from "@/api/user";
import { getWithdrawal, setWithdrawal } from "@/api/money";
import store from "@/store";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
const router = useRouter();
const { userInfo } = store.getters;
const showPicker = ref(false);
const bankName = ref("");
const columns = ref([]);
const resData = ref({});
const imgCaptcha = ref(getCaptcha());
const form = reactive({
  money: "",
  user_bank_id: "",
  password: "",
  captcha: "",
});
const toastLoading = Toast.loading({
  duration: 0,
  forbidClick: true,
});
// const onConfirm = (value, index) => {

//   form.user_bank_id = resData.value.user_banks[index].id;
//   showPicker.value = false;
// };
getWithdrawal().then((res) => {
  Toast.clear();
  if (res.status === 0) {
    if (res.data.user_banks) {
      res.data.user_banks.forEach((item) => {
        bankName.value = item.account;
        form.user_bank_id = item.id;
        // columns.value.push(item.account);
      });
    }
    resData.value = res.data;
  } else {
    Dialog.alert({
      message: res.msg,
    }).then(() => {
      if (res.msg.indexOf("密码") !== -1) {
        router.replace("/pages/passwordChange?type=safe&isOff=true");
      } else {
        router.go(-1);
      }
    });
  }
  console.log(res.data);
});
const onSubmit = () => {
  const toastLoading2 = Toast.loading({
    duration: 0,
    forbidClick: true,
  });
  setWithdrawal(toRaw(form))
    .then((res) => {
      Toast.success({ duration: 1500, message: res.msg });
      setTimeout(() => {
        router.push("/my");
      }, 1500);
    })
    .catch(() => {
      imgCaptcha.value = getCaptcha();
      toastLoading2.clear();
    });
};
</script>

<style lang="less" scoped>
.withdrawal {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;

  .card {
    display: flex;
    justify-content: left;
    /* 水平居中 */
    align-items: center;
    justify-content: space-between;
    font-size: 18px;

    i:first-child {
      font-size: 40px;
      margin-right: 20px;
    }

    span {
      width: 80%;
    }
  }

  .van-cell-group--inset {
    margin: 10px 0;
  }
}
</style>
